<template>
    <div class="hint">
        <div><img src="../../../assets/pc/images/login/hint_login.png"></div>
        <div style="margin-top:20px">您还没登录呢！</div>
        <div style="margin-top:20px">请先登录账号，以便查阅更多政策资讯哦~</div>
        <div style="margin-top:40px"><el-button type="primary" @click="handleLogin">去登录</el-button></div>
    </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  methods: {
    handleLogin () {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style lang="less" scoped>
    .hint{
        background-color: #fff;
        height: 400px;
        padding-top: 200px;
        text-align: center;
        font-size: 18px;
    }
    .el-button--primary{
    height:40px;
    width: 150px;
    line-height: 0.4;
    background-color:#156ED0;
    border:1px solid #156ED0;
    border-radius:5px;
    span {
        font-family:Microsoft YaHei UI;
        color:#fff;
        font-size:18px;
        letter-spacing:1px;
    }
}
</style>
